<script setup lang="ts">
import { computed, ref, watch, defineAsyncComponent } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { storeToRefs } from 'pinia'
import { useUserStoreHook } from "@/store/modules/user";
import settings from "@/utils/settings";
import { queryMessageListApi, authApplyApi, blackApi } from "@/api/message";
import NoticeItem from "@/views/member/notice/components/NoticeItem.vue";

const userStore = useUserStoreHook()
const { userInfo, siteInfo } = storeToRefs(userStore)

const SelectAuthView = defineAsyncComponent(() => import('@/views/member/notice/components/SelectAuth.vue'))
const selectAuthRef = ref();

interface props {
  tabId: string  // 选中的标签,
  loading: boolean, // 加载中
}

const props = defineProps<props>()

/**
 * 消息列表
 */
const noticeList = ref<any>([]);

/**
 * 翻页
 */
interface page {
  page: number,
  limit: number
}

const page = ref<page>({
  page: 1,
  limit: 15
});

const {pageSizes} = settings;

/**
 * 总条数
 */
const total = ref<number>(0);

// const loading = ref(false);

/**
 * 获取消息列表
 */
const getMessageList = async () => {
  try {
    emit('update:loading', true)
    const data: any = await queryMessageListApi({
      ...page.value,
      ...{
        type: props.tabId
      }
    })
    emit('update:loading', false)
    total.value = data.total || 0;
    noticeList.value = data.data;
    changeNotice(0);
  } catch (error) {
    console.log(error)
  } finally {
    emit('update:loading', false)
  }
}

const emit = defineEmits(['update:loading'])

watch(
  () => props.tabId,
  () => {
    page.value.page = 1;
    noticeList.value = []
    getMessageList();
  },
  { immediate: true }
)

// 当前显示的内容
const acitveIndex = ref<number>(0);
const currentNotice = computed(() => noticeList.value[acitveIndex.value]);

const changeNotice = (index: number) => {
  acitveIndex.value = index
}

const showAuthor = computed(() => {
  if (props.tabId === '2') return true;
  return false;
})

// 是否显示 申请处理 按钮
const showAuth = computed(() => {
  if (props.tabId === '4') return true;
  return false;
})

const statusInfo: any = (item: any = {}) => {
  if (item.bmd && item.bmd.length) return [
    { text: '同意', theme: 'primary', value: 1 },
    { text: '同意并加入当前授权组', theme: 'minor', value: 4 },
    { text: '拒绝', theme: 'cancel', value: 2 }
  ]
  else return [
    { text: '通过', theme: 'primary', value: 1 },
    { text: '拒绝', theme: 'minor', value: 2 },
    { text: '忽略', theme: 'cancel', value: 3 }
  ]
  // if (item.bmd && item.bmd.length) return { 1: { text: '同意', theme: 'primary' }, 4: { text: '同意并加入当前授权组', theme: 'minor'}, 2: {text: '拒绝', theme: 'cancel'} }
  // else return {1: {text: '通过', theme: 'primary'}, 2: {text: '拒绝', theme: 'minor'}, 3: {text: '忽略', theme: 'cancel'} }
}

const authApply = async (status: number) => {
  if (status == 4) {
    selectAuthRef.value && selectAuthRef.value.selectAuth(currentNotice.value);
    return
  }
  let text = statusInfo(currentNotice.value).find((item: any) => item.value == status)?.text;
  await ElMessageBox.confirm(`是否${text}该申请`, '提示', { type: 'warning' });
  try {
    const result: any = await authApplyApi({
      id: currentNotice.value.id,
      status: status
    })
    ElMessage.success(result || '操作成功');
    getMessageList();
  } catch (error) {
    
  }
}

// 处理标题
const handleTitle = (item: any, tag: Boolean = true) => {
  if (!item.user_name || !item.file_name) return item.title;
  return tag ? `<span style="color: #FF7B6D">${item.user_name}</span>申请访问文档<span style="font-weight: 600;">《${item.file_name}》</span>` : `${item.user_name}申请访问文档《${item.file_name}》`;
}

// 多选 授权处理
const authApplyMall = async (status: number) => {
  const ids: any = [];
  noticeList.value.forEach((item: any) => {
    if (item.checked) ids.push(item.id);
  })
  if (!ids.length) return ElMessage.warning('请选择要操作的内容');
  try {
    const result: any = await authApplyApi({
      id: ids.join(','),
      status: status
    })
    ElMessage.success('操作成功');
    getMessageList();
  } catch (error) {

  }
}

/* 拉黑 */
const showBlack = computed(() => {
  if (props.tabId === '3') return true;
  return false;
})

// 添加黑名单
const addBlack = async (item: any) => {
  await ElMessageBox.confirm(`是否加入黑名单`, '提示', { type: 'warning' });
  try {
    const result: any = await blackApi({
      lh_user_id: item.user_id,
      file_manage_id: item.file_manage_id,
      log_id: item.log_id
    })
    ElMessage.success('操作成功');
    getMessageList();
  } catch (error) {

  }
}

</script>

<template>
  <div v-loading="loading" class="list-wrap flex-auto flex">
    <template v-if="noticeList.length">
      <!-- 左侧列表 -->
      <div class="left-wrap flex-y mr18">
        <div class="flex-auto">
          <el-scrollbar class="notice-wrap">
            <template v-if="showAuth">
              <div class="notice-item flex-a-center cursor-pointer action">
                <p-button v-for="(item, index) in statusInfo()" :key="index" theme="link" :text="item.text"
                  @click="authApplyMall(item.value)" />
              </div>
            </template>
            <div class="notice-item flex-a-center cursor-pointer" :class="{'active': acitveIndex == index}"
              v-for="(item, index) in noticeList" :key="item.id" @click="changeNotice(index)">
              <template v-if="showAuth">
                <el-checkbox class="ml10" v-model="item.checked" />
                <p class="title text-ellipsis-1 pl10" :title="handleTitle(item, false)" v-html="handleTitle(item)"></p>
              </template>
              <template v-else-if="showBlack">
                <p class="title text-ellipsis-1 pr10 flex-auto" :title="item.title">{{ item.title }}</p>
                <p-button v-if="item.lh_status == 0" theme="link" text="拉黑" @click="addBlack(item)" />
                <p-button v-if="item.lh_status == 1" theme="link" text="已拉黑" />
              </template>
              <p v-else class="title text-ellipsis-1" :title="item.title">{{ item.title }}</p>
            </div>
          </el-scrollbar>
        </div>
        <div class="pagination flex-c-center mt20">
          <el-pagination v-model:current-page="page.page" v-model:page-size="page.limit" :pager-count="2" size="small"
            :page-sizes="pageSizes" background layout="total, prev, pager, next, jumper" :hide-on-single-page="true"
            :total="total" @current-change="getMessageList" />
        </div>
      </div>
      <!-- 右侧内容 -->
      <div class="right-wrap flex-auto">
        <el-scrollbar class="content-wrap p30">
          <!-- 标题 -->
          <div v-if="showAuthor" class="title mb26">{{ currentNotice.title }}</div>
          <div v-if="showAuthor" class="tips mb10">尊敬的{{ userInfo.username }}您好：</div>
          <!-- 授权申请操作 -->
          <template v-if="showAuth">
            <div class="content content-auth flex-c-center">
              <p v-html="handleTitle(currentNotice)"></p>
            </div>
            <div class="flex-c-end mt20">
              <p-button v-for="(item, index) in statusInfo(currentNotice)" class="ml20" :key="index" :theme="item.theme"
                :text="item.text" @click="authApply(item.value)" />
            </div>
            <select-auth-view ref="selectAuthRef" @success="getMessageList" />
          </template>
          <!-- 文件提醒 拉黑 -->
          <template v-else-if="showBlack">
            <div class="content">
              <p v-if="currentNotice.lh_status == 2" v-html="currentNotice.content"></p>
              <div v-else class="black-info-wrap w100">
                <div class="flex-c-between mb20">
                  <p class="b-title">{{ currentNotice.title }}</p>
                  <p class="b-time">{{ currentNotice.createtime }}</p>
                </div>
                <p class="b-text">访客 {{ currentNotice.user_name }} 在{{ currentNotice.createtime }} 访问了《{{
                  currentNotice.file_name }}》，第5次访问</p>
                <p class="b-text">访问ip：{{ currentNotice.ip }}</p>
                <p class="b-text">访问设备：{{ currentNotice.shebei }}</p>
                <p class="b-text">访问平台：{{ currentNotice.pingtai }}</p>
                <p class="b-text">访问时长：{{ currentNotice.timenum }}</p>
                <p class="b-text">访问进度：{{ currentNotice.plan }}</p>
                <p class="b-text">访问操作：{{ currentNotice.caozuo || '无' }}</p>
              </div>
            </div>
            <div class="flex-c-end mt50">
              <p-button v-if="currentNotice.lh_status == 0" text="拉黑" @click="addBlack(currentNotice)" />
            </div>
          </template>
          <div v-else :class="['content', {'indent': showAuthor}]" v-html="currentNotice.content"></div>
          <!-- 右下角来源 -->
          <div v-if="showAuthor" class="flex-y mt26" style="align-items: flex-end;">
            <p class="author mb10">笔熊分享</p>
            <!-- 时间 -->
            <p class="time">{{ currentNotice.createtime }}</p>
          </div>
        </el-scrollbar>
      </div>
    </template>
    <div v-else class="flex-c-center w100">
      <el-empty style="height: calc(100% - 0.82rem)" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-wrap {
  .left-wrap {
    width: 38%;
    height: 100%;
    .notice-wrap {
      border: 1px solid rgba(180,176,176,0.28);
      .notice-item {
        height: 0.45rem;
        background: #FFFFFF;
        border-bottom: 1px solid rgba(180,176,176,0.28);
        .title {
          font-size: 15px;
          color: #1D1D1D;
          padding: 0 0.22rem;
        }
        &:last-child {
          border: none;
        }
        &.active {
          background: #EEEFF0;
          color: #201F1F;
        }
        &.action {
          background: #FF7B6D;
          :deep(.p-button) {
            .text {
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  .right-wrap {

    .content-wrap {
      background: #FFFFFF;
      box-shadow: 0px 0px 7px 1px rgba(118,118,118,0.04);
      max-height: 100%;
      overflow-y: auto;
      font-size: 0.15rem;
      color: #3D3C3C;
      .title {
        font-size: 0.18rem;
        color: #010101;
      }
      .content {        
        &.indent {
          text-indent: 2em;
        }
        img {
          max-width: 100%;
        }
        &.content-auth {
          height: 50vh;
        }
        .black-info-wrap {
          .b-title {
            font-weight: 400;
            font-size: 0.18rem;
            color: #010101;
          }
          .b-time {
            font-weight: 400;
            font-size: 0.15rem;
            color: #484444;
          }
          .b-text {
            font-weight: 300;
            font-size: 0.15rem;
            color: #3D3C3C;
            line-height: 0.3rem;
          }
        }
      }
    }
  }
}

</style>
