<template>
  <div class="help-container">
    <img v-if="isPC" class="banner" src="@/assets/help/banner.png" alt="">
    <img v-else class="banner banner-m" src="@/assets/help/banner_m.png" alt="">
    <div class="main-content pt100 pb100">
      <h3 class="title pl13">帮助中心</h3>
      <div class="help-table">
        <div
          v-for="(v, i) in state.data"
          :key="i"
          class="help-table-item mt30"
        >
          <h3 class="item-title mb20">{{ v.title }}</h3>
          <p class="item-desc" v-html="v.description"></p>
        </div>
      </div>
      <div v-if="isPC" class="flex-c-center pt40 pb40 page-box">
        <el-pagination background layout="prev, pager, next" v-model:current-page="state.params.page"
            v-model:page-size="state.params.limit" :total="state.config.total" @current-change="getPageInfo" />
      </div>
      <div v-else class="flex-c-center pt40 pb40 page-box-m">
        <el-pagination background layout="prev, next" v-model:current-page="state.params.page"
            v-model:page-size="state.params.limit" :total="state.config.total" @current-change="getPageInfo" />
      </div>
      <!-- 底部固定内容 -->
      <div class="help-foot mt50">
        <p>我们的目标是为您提供最安全、最可控的文件分享体验。无论您是个人用户还是企业用户，我们都为您提供了一站式的解决方案，以确保您分享文件的安全性，并且更好地管理和控制对文件的使用。</p>
        <h3 class="mt20">欢迎尝试使用笔熊分享。我们相信，您将体验到更智能、更安全的文件分享服务。</h3>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import checkPc from '@/utils/checkPc'
import {helpCenterApi} from "@/api/common";

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 定义变量
const state = ref({
  data: [],
  config: {
    total: 0
  },
  params: {
    page: 1,
    limit: 5
  }
})

/**
 * 获取页面信息
 */
const getPageInfo = async () => {
  const list:any = await helpCenterApi(state.value.params);
  console.log(list);
  state.value.data = list.data;
  state.value.config.total = list.total;
}
getPageInfo();
</script>

<style lang="scss" scoped>
.help-container {
  background: var(--next-color-white);
}
.banner {
  height: 5.99rem;
  width: 100%;
  object-fit: cover;
}
.title {
  font-size: 0.34rem;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #191616;
  height: 0.7rem;
  line-height: 0.7rem;
  border: #191616 solid 0.01rem;
  border-left-width: 0;
  border-right-width: 0;
}
.help-table {
  &-item {
    padding: 0.3rem 0.3rem;
    .item-title {
      font-size: 0.32rem;
      color: #191616;
    }
    .item-desc {
      font-size: 0.24rem;
      color: #191616;
      line-height: 0.4rem;
    }
  }
}
.page-box {
  &-m {
    display: none;
  }
}
.help-foot {
  padding: 0 0.3rem;
  p {
    font-size: 0.24rem;
    color: #191616;
    line-height: 0.4rem;
  }
  h3 {
    font-size: 0.32rem;
    color: #191616;
    line-height: 0.6rem;
  }
}
@media screen and (max-width: 768px) {
  .banner {
    display: none;
    &-m {
      display: block;
      height: 3.91rem;
    }
  }
  .main-content {
    padding: 0.6rem 0.5rem !important;
    .title {
      font-size: .29rem;
    }
    .help-table {
      &-item {
        padding: 0.2rem 0.2rem;
        .item-title {
          font-size: 0.24rem;
        }
        .item-desc {
          font-size: 0.2rem;
        }
      }
    }
    .page-box {
      display: none;
      &-m {
        display: flex;
      }
    }
    .help-foot {
      display: none;
    }
  }
}
</style>
