<script setup lang="ts">
import {defineAsyncComponent, ref, watch, computed, onMounted} from 'vue'
import { fileTrackApi } from '@/api/file'
import {useRoute} from "vue-router";
import { useAddress } from '@/utils/getAddress';
import Tips from '@/components/Tips/Tips.vue';
import { useVisiter } from '../hooks/useVisiter';
import checkPc from '@/utils/checkPc'

const isPC = checkPc()

const { handleAction, editBlack, headerDragend } = useVisiter()

// 导入组件
const DataSummary = defineAsyncComponent(() => import("@/views/member/file/components/visiter/DataSummary.vue"));
const TrackItem = defineAsyncComponent(() => import("@/views/member/file/components/phone/TrackItem.vue"));

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const route = useRoute();

/**
 * 文件列表处理
 */
const tableLoading = ref(false)
const tableData = ref<any>([])
const tableParams = ref({ id: '', limit: 10, page: 1, title: '' })
const tableTotal = ref(0)
// 获取文件列表
const getFileList = async () => {
  tableLoading.value = true
  try {
    const res: any = await fileTrackApi(tableParams.value)
    // console.log(res)
    tableData.value = res.data
    tableTotal.value = res.total
  } catch (error) {}
  finally {
    tableLoading.value = false
  }
}

const fileTitle = computed(() => {
  return props.data?.title || route.query.title || '';
})

// watch(
//   () => props.data,
//   () => {
//     if (props.data && props.data.id) {
//       tableParams.value.id = props.data.id
//     }else{
//       tableParams.value.id = '' + route.query.id
//     }
//     if (tableParams.value.id){
//       tableData.value = []
//       tableTotal.value = 0;
//       tableParams.value.page = 1
//       getFileList()
//     }
//   },
//   { deep: true, immediate: true }
// )

const refresh = (init = false) => {
  if (props.data && props.data.id) {
    tableParams.value.id = props.data.id
  } else {
    tableParams.value.id = '' + route.query.id
  }
  if (!tableParams.value.id) return
  if (init) {
    tableData.value = []
    tableTotal.value = 0;
    tableParams.value.page = 1
  }
  getFileList()
}

watch(
  () => route,
  () => {
    const { id } = route.query
    if (id) refresh()
  },
  { immediate: true }
)

// 显示图标
const fileIcon = computed(() => {
  const { file_leix } = route.query
  if (file_leix === '2') return require('@/assets/pdf/l-video.png')
  return require('@/assets/pdf/l-pdf.png')
})

// 编辑黑名单
const editBlackAction = async (type: string, row: any) => {
  await editBlack(type, row)
  getFileList()
}

const addressInfo = useAddress();
const { showAddress } = addressInfo;

defineExpose({ refresh })
</script>

<template>
  <!-- 表格 -->
  <div class="track-wrap">
    <div v-if="isPC" class="table-box pc-wrap">
      <el-table
        ref="multipleTableRef"
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%;"
        height="440px"
        size="default"
        border
        @header-dragend="headerDragend"
      >
        <el-table-column prop="createtime" label="时间" width="180" align="center" show-overflow-tooltip />
        <el-table-column prop="nickname" label="用户" align="center" show-overflow-tooltip>
          <template #default="{row}">
            <div class="flex-c-center px-10">
              <img v-if="row.avatar" class="avatar-table mr6" :src="row.avatar" alt="" />
              <span class="text-1" style="max-width: calc(100% - 0.26rem - 0.06rem);">{{row.nickname}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="第几次浏览" width="130" align="center" show-overflow-tooltip />
        <el-table-column prop="address" label="地区" align="center" show-overflow-tooltip>
          <template #default="{row}">
            <span v-if="+row.address_button === 1">{{row.address}}</span>
            <template v-else>
              <el-button v-if="!row.addressStr" type="primary" link @click="showAddress(row, 2, getFileList)" class="font15">点击查看</el-button>
              <span v-else>{{row.addressStr}}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="ip" label="IP" width="150" align="center" show-overflow-tooltip />
        <el-table-column prop="shebei" label="访问设备" width="100" align="center" />
        <el-table-column prop="pingtai" label="访问平台" align="center" show-overflow-tooltip />
        <el-table-column prop="action" label="操作" width="180" align="center">
          <template #default="{row}">
            <div v-if="handleAction(row).length" class="flex-a-center">
              <div class="flex-a-center action-tag" v-for="(item, index) in handleAction(row)" :key="index" :style="{background: item.color}">{{ item.name }}</div>
            </div>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="file_name" label="访问文档" align="center" show-overflow-tooltip />
        <el-table-column prop="timenum" label="停留时长" align="center" show-overflow-tooltip />
        <el-table-column prop="plan" label="浏览进度" width="110" align="center" show-overflow-tooltip />
        <el-table-column prop="black" label="拉黑" width="80" align="center">
          <template #default="{row}">
            <p-button v-if="row.lh_status === 1" class="black-link" theme="link" text="取消拉黑" @click="editBlackAction('del', row)" />
            <p-button v-else class="black-link" theme="link" text="拉黑" @click="editBlackAction('add', row)" />
          </template>
        </el-table-column>
      </el-table>
      <div v-if="tableTotal > 0" class="flex-c-center pt20 pb20">
        <el-pagination background layout="total, prev, pager, next, jumper" v-model:current-page="tableParams.page"
          v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
      </div>
    </div>
    <!--移动端浏览轨迹页面-->
    <div v-else class="phone-wrap h100 pb20">
      <!-- <div class="phone-tips">
        注：自己访问不消耗访问次数和人数
      </div> -->
      <!-- 数据统计 -->
      <div class="phone-top-wrap">
        <div class="file-title flex-a-center">
          <img class="icon" :src="fileIcon" alt="" />
          <span>{{ fileTitle }}</span>
        </div>
        <data-summary v-if="tableParams.id" :id="tableParams.id" theme="phone" class="mt15" />
      </div>

      <div v-loading="tableLoading" class="track flex-auto">
        <template v-if="tableData.length">
          <track-item v-for="(item, index) in tableData" :key="index" :data="item" @showAddress="showAddress(item, 2, getFileList)" @refresh="getFileList" />
        </template>
        <el-empty v-else />
      </div>
      <div class="pagination">
        <el-pagination background layout="prev, next"  v-model:current-page="tableParams.page"
          v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import '../style/visiter.scss';
.track-wrap{
  height: 100%;
  :deep(.el-table) {
    thead {
      .cell {
        overflow-wrap: normal;
        white-space: nowrap;
      }
    }
  }

  .phone-wrap{
    height: 100%;
    background: #F4F5F6;
    padding: 0.2rem 0.3rem;
    display: flex;
    flex-direction: column;
    .phone-tips {
      font-weight: 300;
      font-size: 0.17rem;
      color: #F13939;
      text-align: right;
    }
    .phone-top-wrap {
      padding: 0.26rem 0.28rem;
      margin: 0.13rem -0.09rem 0.04rem;
      height: 2.2rem;
      background: url(@/assets/member/mobile/track-bg.png) no-repeat center center / 100% 100%;
      .file-title {
        .icon {
          width: 0.5rem;
          margin-right: 0.16rem;
        }
        span {
          font-weight: 400;
          font-size: 0.24rem;
          color: #061422;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        }
      }
    }

    .track {
      overflow: auto;
    }

    .pagination{
      margin-top: 0.2rem;

      :deep(.el-pagination){
        justify-content: center;
      }
    }
  }
}
</style>
