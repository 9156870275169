<template>
  <div class="pdf-main">
    <div class="main-content pdf-container">
      <div :class="['flex-column-center', 'pb15', {'pt44': isPC}, {'pt54': !isPC} ]">
        <h3 class="pdf-title">文件加密分享</h3>
        <p class="pdf-desc mt10">上传文件>设置加密方式>分享链接</p>
      </div>
      <!-- 上传 -->
      <UploadView @changeFile="changeFile" @changeFileType="changeFileType" />
    </div>
    <Container :class="[{'pt34': isPC}, {'pt50': !isPC}, 'pb1']" title="加密设置" :showLine="false">
      <!-- :fileIds="fileIds" -->
      <SetFileForm :fileList="fileList" :fileType="fileType" @success="encryptSuccess" />
    </Container>
    <!-- 如何给PDF加密 -->
    <Encryption />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, defineAsyncComponent } from "vue";
import checkPc from '@/utils/checkPc'
import { useRoute, useRouter } from "vue-router"

const route = useRoute()
const router = useRouter()

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 引入组件
const Container = defineAsyncComponent(() => import("@/views/home/components/container.vue"));
const Encryption = defineAsyncComponent(() => import("./components/Encryption.vue"));
const UploadView = defineAsyncComponent(() => import("./components/UploadView.vue"));
const SetFileForm = defineAsyncComponent(() => import("@/components/SetFileForm/index.vue"));

// const fileIds = ref('')
const fileType = ref(1)
const fileList = ref([])

const changeFileType = (type: number) => {
  fileType.value = type
}

const changeFile = (data: any) => {
  console.log(data, 'data')
  let newArray = data.filter((item: any) => item.data)
  newArray = newArray.map((item: any) => {
    if (fileType.value == 1) {
      const { name, size } = item
      return { title: name, url: item.data.name, file_size: size }
    }
    if (fileType.value == 2) {
      const { name, size, data } = item
      const { fileId, video } = data
      return { title: name, url: video.url, video_id: fileId, file_size: size }
    }
  })
  if (newArray.length === data.length) fileList.value = newArray
  else fileList.value = []
}

// 加密成功
const encryptSuccess = (e:any) => {
  console.log(fileList.value, 'fileList.value')
  router.push({ name: 'pdf-result', params: { ids: e } })
}

</script>

<style lang="scss" scoped>
.pdf-main {
  background: var(--next-color-white);
  min-height: calc(100vh - 0.84rem);
  .pdf-container {
    box-sizing: border-box;
    width: 14.46rem;
    .pdf-title {
      font-size: 0.30rem;
      color: #444141;
    }
    .pdf-desc {
      font-size: 0.15rem;
      color: #212121;
    }
  }
}
:deep(.content-container) {
  .title {
    font-size: 0.3rem !important;
  }
}
@media screen and (max-width: 768px) {
  $bgColor: #ffffff;
  .pdf-main {
    background: $bgColor;
    padding: 0 0.28rem;
    .pdf-container {
      width: 100%;
      position: relative;
      // ::before {
      //   content: '';
      //   position: absolute;
      //   width: 0.28rem;
      //   height: calc(100% - 0.53rem);
      //   background: #F4F5F6;
      //   left: -0.28rem;
      //   top: 0;
      // }
      .pdf-title {
        font-weight: bold;
        font-size: 0.33rem;
        color: #333333;
        line-height: 1;
      }
      .pdf-desc {
        font-weight: 400;
        font-size: 0.28rem;
        color: #414141;
        line-height: 1;
        margin-top: 0.14rem !important;
      }
    }
    :deep(.content-container) {
      background: $bgColor;
      .title {
        font-size: 0.33rem !important;
        color: #333333;
      }
    }
  }
}
</style>
