import { ElMessageBox } from 'element-plus';
import router from '@/router';

export const useNotVipTips = () => {

  const tipsObj:any = {
    'qy': {
      tips: '此功能为企业会员功能，需要开通企业会员才能使用。',
      path: '/member/join?type=2'
    },
    'gr': {
      tips: '此功能为个人会员功能，需要开通个人会员才能使用。',
      path: '/member/join?type=1'
    },
    'hy': {
      tips: '此功能为会员功能，需要开通会员才能使用。',
      path: '/member/join'
    },
  }

  const notVipTips = (lowestVip: string = 'hy', tips: string = '') => {
    ElMessageBox.confirm(tips || tipsObj[lowestVip].tips, '提示', {
      confirmButtonText: '去开通',
      cancelButtonText: '取消'
    }).then(() => {
      const route = router?.currentRoute.value
      const toPath = tipsObj[lowestVip].path.includes('?') ? '&' : '?'
      console.log(toPath, 'toPath')
      router.push(`${tipsObj[lowestVip].path}${toPath}redirect=${route.path}&params=${JSON.stringify(route.query ? route.query : route.params)}`)
    }).catch(() => {
      console.log('取消')
    })
  }
  
  return { notVipTips }
}