import request from '@/utils/request';

/**
 * 消息设置查询接口
 */
export const queryMessageSettingApi = () => {
  return request({
    url: '/msg/setlst',
    method: 'get'
  });
}

/**
 * 消息设置保存接口
 */
export const saveMessageSettingApi = (data: any) => {
  return request({
    url: '/msg/set',
    method: 'post',
    data
  });
}

/**
 * 未读提醒
 */
export const queryUnreadRemindApi = () => {
  return request({
    url: '/msg/readnum',
    method: 'get'
  });
}

/**
 * 消息列表
 */
export const queryMessageListApi = (params: any) => {
  return request({
    url: '/msg/lst',
    method: 'get',
    params
  });
}

// 获取未读消息数量
export const queryUnreadMsgNumApi = () => {
  return request({
    url: '/msg/countmsg',
    method: 'get'
  });
}

// 授权申请 操作  id 消息id 多个用逗号隔开   status 状态 1通过 2拒绝 3忽略
export const authApplyApi = (data: any) => {
  return request({
    url: '/msg/queren',
    method: 'post',
    data
  });
}

// 消息全部已读
export const readAllMsgApi = () => {
  return request({
    url: '/msg/allread',
    method: 'get'
  });
}

// 拉黑操作  lh_user_id 要拉黑的用户id 没有id传编号  file_manage_id文件id log_id访客记录id
export const blackApi = (data: any) => {
  return request({
    url: '/lahei/add',
    method: 'post',
    data
  });
}

// 撤销拉黑操作  lh_user_id 要拉黑的用户id 没有id传编号  file_manage_id文件id log_id访客记录id
export const cancelBlackApi = (data: any) => {
  return request({
    url: '/lahei/del',
    method: 'post',
    data
  });
}

// 消息中心 同意用户加入授权组  id（消息id）、bmd_id（白名单id）
export const agreeAuthApi = (data: any) => {
  return request({
    url: '/msg/setbmd',
    method: 'post',
    data
  });
}