<template>
  <div id="result">
    <!-- 加密结果页 -->
    <h1>加密完成</h1>

    <!--  单个文件加密完成  -->
    <div class="main-box">
      <div :class="['card', { batch: isBatch }]">
        <div>
          <div class="wrap" v-for="(item, index) in links" :key="index">
            <!-- 文档标题 -->
            <div class="doc-title">{{ item.title }}</div>
            <!-- 文档链接 -->
            <div class="doc-link">
              <el-input v-model="item.share_url" disabled />
              <template v-if="item.password?.length > 1">
                <span class="input-code-text">密码</span>
                <el-input class="input-code" v-model="item.password" disabled />
              </template>
              <!-- 复制链接按钮 -->
              <el-button type="primary" @click="copyLink" class="copy-btn" :data-clipboard-text="item.copy"
                color="#FE0000">
                复制分享链接
              </el-button>
              <el-button v-if="isWx && !isWxWork" color="#FE0000" round class="ml10 copy-btn" 
                @click="shareApplet(item.applet_url)">小程序分享</el-button>
              <!-- 保存二维码 -->
              <el-button v-if="isBatch" type="primary" @click="saveCode({...item, save_type: 'download'})" class="copy-btn" color="#FE0000">
                <!-- {{ saveQrCodeTips }} -->保存二维码
              </el-button>
            </div>
          </div>
          <div v-if="isBatch" class="ewm-file" style="position: absolute; top: -1000px;">
            <qrcode-vue ref="qrCodeRef" :value="nowItemLink" :size="qrCodeSize"></qrcode-vue>
          </div>
          <!--<div class="doc-share" v-if="!isBatch && links[0]">
          <!~~ 二维码 ~~>
          <div class="qr-code">
            <qrcode-vue :value="links[0]?.share_url" :size="500"></qrcode-vue>
          </div>

          <!~~ 分享提示 ~~>
          <div class="share-tip">
            将二维码分享给好友 <br>
            对方扫一扫即可阅览
          </div>
        </div>-->
        </div>
      </div>
      <!-- 分享二维码 批量不显示 -->
      <div class="container-box flex" v-if="!isBatch && links[0]">
        <div class="card wx-share">
          <div class="wrap">
            <div class="doc-title">微信文件</div>
            <div class="doc-share flex-column-center">
              <div class="img-box flex-a-center" style="height: 2rem;">
                <img style="" :src="shareImgUrl" alt="" srcset="">
              </div>
              <el-button type="primary" @click="shareLink(links[0])" class="copy-btn" color="#FE0000">
                {{ shareText }}
              </el-button>
            </div>
          </div>
        </div>
        <div class="card ewm">
          <div class="wrap">
            <div class="doc-title">二维码</div>
            <div class="doc-share flex-column-center">
              <div class="img-box flex-a-center" style="height: 2rem;">
                <!-- <div class="qr-code">
                  <qrcode-vue ref="qrCodeRef" :value="links[0]?.share_url" :size="200"></qrcode-vue>

                </div> -->
                <div v-if="showQrCode" class="qr-code">
                  <qrcode-vue v-show="false" ref="qrCodeRef" :value="links[0]?.share_url" :size="qrCodeSize" />
                  <img v-if="qrCodeImg" :src="qrCodeImg" alt="" />
                </div>
                <img v-else class="qr-code" src="@/assets/load-data.gif" alt="" />
              </div>
              <el-button type="primary" @click="saveCode(links[0])" class="copy-btn" color="#FE0000">
                {{ saveQrCodeTips }}
              </el-button>
            </div>
          </div>
        </div>
      </div><!-- 按钮组 -->
      <div class="btn-group">
        <el-button @click="batchCopy" class="copy-btn-all copy-btn-bot">一键复制</el-button>

        <el-button @click="shareLink" class="copy-btn-all share-btn"
          style="color: #101010; border-color: rgba(254,0,0,0.24);">发送微信文件教程</el-button>

        <el-button class="return-btn" type="primary" color="#FE0000" @click="toPdf">继续加密</el-button>

        <el-button @click="toManage" class="to-manage">管理我的文档</el-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, defineAsyncComponent, nextTick, watch } from "vue";
import { ElMessage } from "element-plus";
import QrcodeVue from "qrcode.vue";
import ClipboardJS from 'clipboard';
import { useCheckFileOptionsAuth } from '@/utils/checkFileOptionsAuth'
import { useUser } from "@/composable/useUser"
import { useRouter, useRoute } from "vue-router";
import { fileShareApi } from "@/api/file"
import { useFile } from '@/composable/useFile';
import { useFileItem } from '@/views/member/file/components/phone/hooks/file';

const { checkFileOptionsAuth } = useCheckFileOptionsAuth();
const { copyText } = useFileItem()

const { saveQrCode, shareLink, saveQrCodeTips, qrCodeSize, copyLink, shareText,
  getShareAppletUrl, shareApplet, isWx, isWxWork, isPC, shareImg
} = useFile()

const router = useRouter();
const route = useRoute();
const { handleVipTip } = useUser()

// 导入组件
// const QrcodeVue = defineAsyncComponent(() => import("qrcode.vue"));
/**
 *  加密链接
 */
const links = ref<any>([]);

/**
 * 是否是批量
 */
const isBatch = computed(() => {
  return links.value.length > 1;
});

/**
 * 批量加密连接
 */
const linkStr = computed(() => {
  return links.value.map((link: any) => link.copy).join('\n');
});

// 批量复制链接
const batchCopy = async () => {
  await checkFileOptionsAuth('batch_sharing')
  copyText(linkStr.value)
}

// 批量保存二维码
const nowItemLink = ref('')
const qrCodeRef: any = ref(null)
const saveCode = (item: any) => {
  nowItemLink.value = item.share_url
  nextTick(() => {
    const canvas = qrCodeRef.value.$el;
    if (canvas) {
      // 将canvas转为图片
      let img = canvas.toDataURL('image/png', 1);
      saveQrCode(img, item);
    }
  })
}

/**
 * 继续加密
 */
const toPdf = () => {
  router.push('/pdf/index');
}

/**
 * 跳转管理文档页
 */
const toManage = () => {
  router.push('/member/file');
}

const fileIds = ref<any>('')

const shareImgUrl = ref('')
// 获取加密成功文件列表
const getShareList = async () => {
  const res: any = await fileShareApi({ id: fileIds.value })
  if (res && res.length) {
    links.value = res
    getAppletUrl()
    shareImgUrl.value = shareImg(res[0])
  } else {
    await router.replace('/')
  }
}

const qrCodeImg = ref('');
const showQrCode = ref(false)

watch(
  () => links.value,
  (newVal) => {
    if (newVal && newVal.length && newVal[0].share_url) {
      setTimeout(() => {
        showQrCode.value = true
        nextTick(() => {
          const canvas = qrCodeRef.value.$el;
          if (canvas) {
            //   将canvas转为图片
            qrCodeImg.value = canvas.toDataURL('image/png', 1);
          }
        })
      }, 100)
    }
  },
  { immediate: true }
)

const getAppletUrl = () => {
  if (isWx.value && !isWxWork.value) {
    links.value.forEach((item: any) => {
      getShareAppletUrl(item.id).then((res: any) => {
        item.applet_url = res
      })
    })
  }
}

onMounted(() => {
  let { ids } = route.params
  if (ids)  fileIds.value = ids, getShareList()
  else {
    ElMessage.error('参数错误')
    setTimeout(() => {
      router.push('/')
    }, 1500)
  }
})

// css 所需背景图
const bg = `url(${require('@/assets/login/bg.png')})`;
const bg2 = `url(${require('@/assets/pdf/bg2.png')})`;
const qrCodeBg = `url(${require('@/assets/pdf/ewm_bg.png')})`;
</script>

<style lang="scss" scoped>
#result {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: v-bind(bg) no-repeat center/100% 100%;
  padding-bottom: 1.19rem;

  h1 {
    text-align: center;
    color: #222222;
    font-size: 0.5rem;
    margin-top: 0.44rem;
  }

  .main-box {
    width: 12rem;
    margin: 0.37rem auto 0;

    .copy-btn {
      width: 2.29rem;
      height: 0.52rem;
      border-radius: 0.62rem;
      margin-left: 0.11rem;
      font-size: 0.22rem;
    }

    .container-box {
      margin-top: 0.2rem;
      flex-direction: row-reverse;
    }
  }

  .card {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px 7px rgba(254, 0, 0, 0.08);
    border-radius: 0.2rem;
    padding: 0.3rem 0.3rem;

    &.batch {
      height: 5.6rem;
    }

    &.wx-share {
      // width: 7.4rem;
      width: calc(50% - 0.1rem);
      padding: 0.3rem;
      .img-box {
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
    }

    &.ewm {
      flex: 1;
      margin-right: 0.2rem;
      padding: 0.3rem;
    }

    &>div {
      height: 100%;
      overflow: auto;
      margin-right: -0.2rem;
      padding-right: 0.2rem;
    }

    .wrap+.wrap {
      margin-top: 0.32rem;
    }

    .doc-title {
      // padding-left: 0.21rem;
      display: flex;
      align-items: center;
      font-size: 0.17rem;
      color: #222222;

      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background: #FF0A0D;
        border-radius: 50%;
        margin-right: 0.08rem;
      }
    }

    .doc-link {
      margin-top: 0.14rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      :deep(.el-input__wrapper) {
        border-radius: 0.62rem;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.24) inset;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        background-color: transparent;

        .el-input__inner {
          flex: 1;
          height: 0.52rem;
          color: #222222;
          font-size: 0.17rem;
          cursor: auto;
        }
      }

      .input-code-text {
        display: inline-block;
        text-align: center;
        font-size: 0.2rem;
        flex-shrink: 0;
        margin: 0 0.1rem;
      }

      .input-code {
        width: 2.2rem;
      }


    }

    .doc-share {
      // margin-top: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .qr-code {
        width: 1.6rem;
        height: 1.6rem;
        background: v-bind(qrCodeBg) no-repeat center/100%;
        padding: 0.12rem;

        canvas,
        img {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .share-tip {
        font-size: 0.3rem;
        color: #202020;
        margin-left: 0.74rem;
      }
    }

    &.batch {
      :deep(.el-input__wrapper) {
        .el-input__inner {
          height: 0.43rem;
        }
      }

      :deep(.el-button) {
        height: 0.43rem;
      }
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-around;
    // width: 11.42rem;
    margin: 0.52rem auto 0;
    // padding: 0 1rem;

    :deep(.el-button) {
      min-width: 2.2rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      font-size: 0.22rem;
      margin-left: 0.5rem;

      &:first-child {
        margin-left: 0;
      }
    }

    .copy-btn {
      border: 2px solid #FE0000;
      color: #FE0000;
    }

    .to-manage {
      border: 2px solid rgba(254, 0, 0, 0.24);
      color: #101010;
    }
  }
}

@media screen and (max-width: 768px) {
  #result {
    padding: 0 0.28rem;
    background: v-bind(bg2) no-repeat center/100% 100%;

    h1 {
      font-size: 0.36rem;
      margin-top: 0.57rem;
    }

    .main-box {
      width: 100%;

      .container-box {
        .card {
          width: calc(100% / 2 - 0.1rem);
          margin-top: 0;

          .img-box {
            height: 3rem !important;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .card {
      width: 100%;
      // height: 10.23rem;
      height: auto;
      margin-top: 0.42rem;
      padding: 0.67rem 0.31rem;

      &.batch {
        height: auto;
        max-width: 10.23rem;
      }

      .wrap+.wrap {
        margin-top: 0.4rem;
      }

      .doc-title {
        font-size: 0.24rem;
      }

      .doc-link {
        margin-top: 0.3rem;
        flex-wrap: wrap;
        justify-content: flex-start;

        :deep(.el-input.is-disabled:first-of-type) {
          margin-bottom: 0.2rem;
        }

        :deep(.el-input__wrapper) {
          .el-input__inner {
            height: 0.58rem;
          }
        }

        .copy-btn {
          height: 0.58rem;
          font-size: 0.2rem;
          padding: 0 0.2rem;
          width: auto;
          margin-bottom: 0.2rem;
        }
        .input-code-text {
          margin-bottom: 0.2rem;
        }
        .input-code {
          width: 1.2rem;
          margin-bottom: 0.2rem;
          :deep(.el-input__wrapper) {
            .el-input__inner {
              font-size: 0.2rem;
            }
          }
        }
      }

      .doc-share {
        display: block;
        text-align: center;

        .qr-code {
          width: 2.6rem;
          height: 2.6rem;
          margin: auto;
          padding: 0.18rem;
        }

        .share-tip {
          margin-left: 0;
          margin-top: 0.6rem;
          font-size: 0.28rem;
          line-height: 0.38rem;
        }
      }
    }

    .btn-group {
      margin-top: 0.76rem;
      padding: 0 0.88rem;
      width: 100%;
      flex-wrap: wrap;

      :deep(.el-button) {
        min-width: 2.03rem;
        margin-left: 0rem;
        margin-bottom: 0.27rem;
      }

      .copy-btn,
      .to-manage {
        border-color: #fff;
        background-color: #fff;
      }

      .share-btn, .to-manage {
        // display: none;
        flex: 1;
        margin-left: 0.27rem;
      }
      .copy-btn-bot, .return-btn {
        width: 2rem;
      }
      .return-btn, .to-manage, .copy-btn-bot {
        background: #FFFFFF;
        border: 1px solid rgba(190,188,188,0.39);
        color: #393737;
      }
      .share-btn {
        background: #F41714;
        border: 1px solid rgba(255,255,255,0.39);
        color: #FFFFFF !important;
      }
    }
  }
}
</style>