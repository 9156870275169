/**
 * 日期格式转换
 * @param {String} time 时间戳
 * @param {String} format 日期格式 例：Y-m-d h:i:s
 * Y-m-d h:i:s 转换为2021-09-01 12:30:30
 * m-d h:i:s 转换为09-01 12:30:30
 * m-d h:i 转换为09-01 12:30
 * Y年m月d日h时i分s秒 转换为2021年09月01日12时30分30秒
*/
export const timeStamp2String = (time:number, format:string) => {
	const dateTime = new Date()
	dateTime.setTime(time)
	if (time.toString().length == 10) {
		dateTime.setTime(time * 1000)
	}
	const year = dateTime.getFullYear()
	const month = dateTime.getMonth() + 1 < 10 ? '0' + (dateTime.getMonth() + 1) : dateTime.getMonth() + 1
	const date = dateTime.getDate() < 10 ? '0' + dateTime.getDate() : dateTime.getDate()
	const hour = dateTime.getHours() < 10 ? '0' + dateTime.getHours() : dateTime.getHours()
	const minute = dateTime.getMinutes() < 10 ? '0' + dateTime.getMinutes() : dateTime.getMinutes()
	const second = dateTime.getSeconds() < 10 ? '0' + dateTime.getSeconds() : dateTime.getSeconds()
	// 返回字符串格式
	let dateInfo = ''
	const yIndex = format.search('Y')
	const mIndex = format.search('m')
	const dIndex = format.search('d')
	const hIndex = format.search('h')
	const iIndex = format.search('i')
	const sIndex = format.search('s')
	dateInfo += `${str(year, yIndex)}`
	dateInfo += `${str(month, mIndex)}`
	dateInfo += `${str(date, dIndex)}`
	dateInfo += `${str(hour, hIndex)}`
	dateInfo += `${str(minute, iIndex)}`
	dateInfo += `${str(second, sIndex)}`
	return dateInfo
	function str(number:any, index:number) {
		if (index > -1) return `${number}${format.slice(index + 1, index + 2)}`
		else return ''
	}
}

// 判断是否为微信浏览器
export function isWxBrowser() {
	let ua = navigator.userAgent.toLowerCase();
	// return /micromessenger/.test(ua) && !/wxwork/.test(ua) ? true : false;
	return /micromessenger/.test(ua) ? true : false;
}

// 判断是否为企业微信浏览器
export function isWxWorkBrowser() {
	let ua = navigator.userAgent.toLowerCase();
	return /wxwork/.test(ua) ? true : false;
}

// 获取地址栏参数
export function getUrlParam(name:string) {
	// 获取参数
	var url = window.location.search;
	// 正则筛选地址栏
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	// 匹配目标参数
	var result = url.substr(1).match(reg);
	//返回参数值
	return result ? decodeURIComponent(result[2]) : null;
}

// 清除所有cookie
export function clearCookie() {
	var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
	if (keys) {
		for (var i = keys.length; i--;)
			document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
	}
}

// 数组对象去重

/** 数组去重
 * 思路：利用数组原型对象上的 includes 方法
 * @param arr 需要去重的数组
 * @param t 根据 t 字段(指定)进行去重
 * @returns {*[]} 已去重后的数据
 */
export const deduplicate = (arr:any, t:string = "id") => {
  const newArr:any = [],
    assignList:any = []
  if (t) { // 有指定字段
    for (let i = 0; i < arr.length; i++) {
      if (!assignList.includes(arr[i][t])) {
        assignList.push(arr[i][t])
        newArr.push(arr[i])
      }
    }
  } else { // 无指定字段
    for (let i = 0; i < arr.length; i++) {
      if (!newArr.includes(arr[i])) newArr.push(arr[i])
    }
  }
  return newArr
}

// 文件大小换算
export const bytesToSize = (bytes: number) => {
	if (bytes == null) return '--'
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'M', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return (bytes / Math.pow(k, i)).toPrecision(3) + sizes[i]
}